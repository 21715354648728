<template>
  <section>
    <b-card
      v-if="!isShowChart"
      class="mb-0"
      style="margin-bottom: 10px !important;"
    >
      <div>
        <div>
          <div>
            <b-row>
              <b-col cols="2">
                <div class="mb-2">
                  <treeselect
                    v-model="activeTimeSelect"
                    v-format-v-select
                    :options="timeOption"
                    :limit="0"
                    :limit-text="(count) => ` + ${count} lựa chọn`"
                    no-options-text="Không có dữ liệu"
                    no-results-text="Không có dữ liệu"
                    placeholder="Chọn kiểu"
                    :clear-on-select="true"
                    @input="activeTime"
                  />
                </div>
              </b-col>
              <b-col
                v-if="activeTimeSelect === 1"
                cols="2"
              >
                <div class="mb-2">
                  <div>
                    <span>
                      <treeselect
                        v-model="time.month"
                        v-format-v-select
                        :options="MONTHS"
                        class="select-year"
                        placeholder="Chọn tháng..."
                        :normalizer="normalizer"
                        no-options-text="Không có dữ liệu"
                        no-results-text="Không có dữ liệu"
                        @input="getDataTinhHinhXuLyHoSo()
                                getDataGiaiQuyetHoSo()
                                getDataHinhThucNop()
                                getDataThuPhiLePhi()
                                getDataXuLyHoSo()"
                      />
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col
                v-if="activeTimeSelect === 2"
                cols="2"
              >
                <div
                  class="mb-2"
                >
                  <treeselect
                    v-model="time.quarter"
                    v-format-v-select
                    :options="QUARTERS"
                    class="select-year"
                    placeholder="Chọn quý..."
                    :normalizer="normalizer"
                    no-options-text="Không có dữ liệu"
                    no-results-text="Không có dữ liệu"
                    @input="getDataTinhHinhXuLyHoSo()
                            getDataGiaiQuyetHoSo()
                            getDataHinhThucNop()
                            getDataThuPhiLePhi()
                            getDataXuLyHoSo()"
                  />
                </div>
              </b-col>
              <b-col
                v-if="activeTimeSelect === 4"
                cols="2"
              >
                <div
                  class="mb-2"
                >
                  <date-picker
                    v-model="time.date"
                    class="w-100 picker-filter"
                    range
                    placeholder="Chọn ngày tùy chọn..."
                    format="DD/MM/YYYY"
                    @input="getDataTinhHinhXuLyHoSo()
                            getDataGiaiQuyetHoSo()
                            getDataHinhThucNop()
                            getDataThuPhiLePhi()
                            getDataXuLyHoSo()"
                  />
                </div>
              </b-col>
              <b-col
                cols="2"
              >
                <div>
                  <span>
                    <treeselect
                      v-model="time.year"
                      v-format-v-select
                      :options="YEARS()"
                      class="select-year"
                      :class="activeTimeSelect === 4 ? 'd-none' : ''"
                      placeholder="Chọn năm..."
                      :normalizer="normalizer"
                      no-options-text="Không có dữ liệu"
                      no-results-text="Không có dữ liệu"
                      @input="getDataTinhHinhXuLyHoSo()
                              getDataGiaiQuyetHoSo()
                              getDataHinhThucNop()
                              getDataThuPhiLePhi()
                              getDataXuLyHoSo()"
                    />
                  </span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="mb-1 text-uppercase">
        <b>Tình hình xử lý hồ sơ</b>
      </div>
      <div class="custom-grid-xl-container row-gap-35 mb-2">
        <khung-thong-ke
          v-for="item in thongKeXuLyHoSo"
          :key="item.id"
          :khung="item"
          :class="item.class"
          :filter="filter"
        />
      </div>
    </b-card>
    <div class="custom-grid-xl-container row-gap-10">
      <khung-chart
        v-if="permisionChart[0]"
        title="Tỷ lệ hồ sơ TTHC theo hình thức nộp"
        class="grap-6"
        :label="['Trực tiếp','Trực tuyến']"
        :series="hinhThucNop"
        :percentage="hinhThucNopPercentage"
      />
      <khung-chart
        v-if="permisionChart[1]"
        title="Tình hình thu phí, lệ phí"
        class="grap-6"
        :label="['Cần thu phí, lệ phí','Chờ thu phí, lệ phí', 'Đã thu phí, lệ phí']"
        :series="thuPhiLePhi"
        :percentage="thuPhiLePhiPercentage"
      />
      <khung-chart
        v-if="permisionChart[2]"
        title="Tình hình giải quyết hồ sơ"
        class="grap-6"
        :label="['Chưa có kết quả','Đã có kết quả']"
        :series="giaiQuyetHoSo"
        :percentage="giaiQuyetHoSoPercentage"
      />
      <khung-chart
        v-if="permisionChart[3]"
        title="Kết quả xử lý hồ sơ"
        class="grap-6"
        :label="['Hồ sơ giải quyết','Hồ sơ từ chối giải quyết']"
        :series="xuLyHoSo"
        :percentage="xuLyHoSoPercentage"
      />
    </div>
  </section>
</template>

<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import KhungChart from '@/modules/trang-chu/components/common/KhungChart.vue'
import KhungThongKe from '@/modules/trang-chu/components/common/KhungThongKe.vue'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import {
  removeDiacritical, formatDateRequestTime,
} from '@/utils/index'
import { YEARS, QUARTERS, MONTHS } from '../constants/constants'

export default {
  name: 'TrangChu',
  components: {
    BCard,
    DatePicker,
    BRow,
    BCol,
    KhungChart,
    KhungThongKe,
  },
  data() {
    return {
      YEARS,
      QUARTERS,
      MONTHS,
      moment,
      time: {
        year: new Date().getFullYear(),
        quarter: null,
        month: null,
        date: null,
      },
      activeTimeSelect: 1,
      timeOption: [
        {
          label: 'Tháng',
          id: 1,
          active: true,
        },
        {
          label: 'Quý',
          id: 2,
          active: false,
        },
        {
          label: 'Năm',
          id: 3,
          active: false,
        },
        {
          label: 'Tuỳ chọn',
          id: 4,
          active: false,
        },
      ],
      thongKeXuLyHoSo: [
        {
          id: 'choTiepNhan',
          name: 'Chờ tiếp nhận',
          value: '',
          path: '/tiep-nhan-ho-so/hs-truc-tuyen/tiep-nhan-ho-so-truc-tuyen',
          class: '',
          color: 'red',
          permision: PERMISSION_NAME.DASBOARD.CHO_TIEP_NHAN,
          isPermision: false,
          svg: 'cho-tiep-nhan-icon.svg',
        },
        {
          id: 'moiTiepNhan',
          name: 'Mới tiếp nhận',
          value: '',
          path: '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs',
          class: '',
          color: 'blue',
          permision: PERMISSION_NAME.DASBOARD.MOI_TIEP_NHAN,
          isPermision: false,
          svg: 'moi-tiep-nhan-icon.svg',
        },
        {
          id: 'dangXuLy',
          name: 'Đang xử lý',
          value: '',
          path: '/xu-ly-ho-so/ho-so-dang-xu-ly/ho-so-dang-xu-ly',
          class: '',
          color: 'yellow',
          permision: PERMISSION_NAME.DASBOARD.DANG_XU_LY,
          isPermision: false,
          svg: 'dang-xu-ly-icon.svg',
        },
        {
          id: 'choKyDuyet',
          name: 'Chờ ký duyệt',
          value: '',
          path: '/xu-ly-ho-so/duyet-de-xuat/duyet-de-xuat',
          class: '',
          color: 'purpor',
          permision: PERMISSION_NAME.DASBOARD.CHO_KY_DUYET,
          isPermision: false,
          svg: 'cho-ky-duyet-icon.svg',
        },
        {
          id: 'daCoKetQua',
          name: 'Đã có kết quả',
          value: '',
          path: '/nhan-ket-qua/nhan-ket-qua-hs/nhan-ket-qua',
          class: '',
          color: 'gray',
          permision: PERMISSION_NAME.DASBOARD.DA_CO_KET_QUA,
          isPermision: false,
          svg: 'da-ket-qua-icon.svg',
        },
        {
          id: 'choTraKetQua',
          name: 'Chờ trả kết quả',
          value: '',
          path: '/tra-ket-qua/hs-giai-quyet/hs-giai-quyet',
          class: '',
          color: 'green',
          permision: PERMISSION_NAME.DASBOARD.CHO_TRA_KET_QUA,
          isPermision: false,
          svg: 'cho-ket-qua-icon.svg',
        },
      ],
      giaiQuyetHoSo: [],
      giaiQuyetHoSoPercentage: [],
      hinhThucNop: [],
      hinhThucNopPercentage: [],
      thuPhiLePhi: [],
      thuPhiLePhiPercentage: [],
      xuLyHoSo: [],
      xuLyHoSoPercentage: [],
      permisionChart: [true, true, true, true],
      filter: {
        tuNgay: '',
        denNgay: '',
      },
    }
  },
  computed: {
    isShowChart() {
      return this.thongKeXuLyHoSo.every(item => item.isPermision === false)
    },
  },
  watch: {
    timeOption: {
      deep: true,
      handler() {
        const now = new Date()
        if (this.timeOption.length > 0) {
          if (this.activeTimeSelect === 1) {
            this.time.month = now.getMonth()
          }
          if (this.activeTimeSelect === 2) {
            const thang = now.getMonth() + 1
            if (thang < 4) {
              this.time.quarter = 0
            } else if (thang < 7) { this.time.quarter = 3 } else if (thang < 10) { this.time.quarter = 6 } else if (thang < 13) { this.time.quarter = 9 }
          }
        }
        this.getDataTinhHinhXuLyHoSo()
        this.getDataGiaiQuyetHoSo()
        this.getDataHinhThucNop()
        this.getDataThuPhiLePhi()
        this.getDataXuLyHoSo()
      },
      immediate: true,
    },
  },
  created() {
    // this.getDataTinhHinhXuLyHoSo()
    // this.getDataGiaiQuyetHoSo()
    // this.getDataHinhThucNop()
    // this.getDataThuPhiLePhi()
    // this.getDataXuLyHoSo()
    this.permisionChart = [hasPermission([PERMISSION_NAME.DASBOARD.TY_LE_HSTTHC_THEO_HINH_THUC_NOP]), hasPermission([PERMISSION_NAME.DASBOARD.TINH_HINH_THU_PHI_LE_PHI]), hasPermission([PERMISSION_NAME.DASBOARD.TINH_HINH_GIAI_QUYET_HO_SO]), hasPermission([PERMISSION_NAME.DASBOARD.KET_QUA_XU_LY_HO_SO])]
    this.numberPermisionChart = this.permisionChart.filter(item => item).length
  },
  methods: {
    formatDateRequestTime,
    getDataTinhHinhXuLyHoSo() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = formatDateRequestTime(date.dateBefore)
        this.filter.denNgay = formatDateRequestTime(date.dateAfter)
        this.$axios.get(`${END_POINTS.TRANG_CHU.TINH_HINH_XU_LY_HO_SO}?tuNgay=${this.filter.tuNgay}&&denNgay=${this.filter.denNgay}`).then(res => {
          if (res.data?.succeeded) {
            const result = res.data.data
            // eslint-disable-next-line prefer-const
            let arr = []
            Object.entries(result).forEach(item => {
            // eslint-disable-next-line prefer-destructuring
              arr[item[0]] = item[1]
            })
            this.thongKeXuLyHoSo = this.thongKeXuLyHoSo.map(item => ({
              ...item,
              value: arr[item.id],
              isPermision: hasPermission([item.permision]),
            }))
            const thongKeXuLyHoSoPermision = this.thongKeXuLyHoSo.filter(item => item.isPermision)
            const count = thongKeXuLyHoSoPermision.length
            // eslint-disable-next-line no-nested-ternary
            const index = count % 3 === 0 ? 3 : count % 2 === 0 ? 2 : 1
            this.thongKeXuLyHoSo = thongKeXuLyHoSoPermision.map((item, idx) => {
              if (index === 3) {
              // eslint-disable-next-line no-param-reassign
                item.class = 'grap-4'
              }
              if (index === 2 || index === 1) {
              // eslint-disable-next-line no-param-reassign
                item.class = 'grap-6'
              }
              if (index === 1 && idx === count - 1) {
              // eslint-disable-next-line no-param-reassign
                item.class = 'grap-12'
              }
              return item
            })
          }
        })
      }
    },
    getDataGiaiQuyetHoSo() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = formatDateRequestTime(date.dateBefore)
        this.filter.denNgay = formatDateRequestTime(date.dateAfter)
        // `${END_POINTS.TRANG_CHU.TINH_HINH_XU_LY_HO_SO}?tuNgay=${this.filter.tuNgay}&&denNgay=${this.filter.denNgay}`
        this.$axios.get(`${END_POINTS.TRANG_CHU.GIAI_QUYET_HO_SO}?tuNgay=${this.filter.tuNgay}&&denNgay=${this.filter.denNgay}`).then(res => {
          if (res.data?.succeeded) {
            const result = res.data.data
            this.giaiQuyetHoSo = [result.hoSoChuaCoKetQua, result.hoSoCoKetQua]
            this.giaiQuyetHoSoPercentage = [result.tyLeChuaCoKetQua, result.tyLeDaCoKetQua]
          }
        })
      }
    },
    getDataHinhThucNop() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = formatDateRequestTime(date.dateBefore)
        this.filter.denNgay = formatDateRequestTime(date.dateAfter)
        this.$axios.get(`${END_POINTS.TRANG_CHU.HINH_THUC_NOP}?tuNgay=${this.filter.tuNgay}&&denNgay=${this.filter.denNgay}`).then(res => {
          if (res.data?.succeeded) {
            const result = res.data.data
            this.hinhThucNop = [result.tiepNhanTrucTiep, result.tiepNhanTrucTuyen]
            this.hinhThucNopPercentage = [result.tyLeTrucTiep, result.tyLeTrucTuyen]
          }
        })
      }
    },
    getDataThuPhiLePhi() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = formatDateRequestTime(date.dateBefore)
        this.filter.denNgay = formatDateRequestTime(date.dateAfter)
        this.$axios.get(`${END_POINTS.TRANG_CHU.THU_PHI_LE_PHI}?tuNgay=${this.filter.tuNgay}&&denNgay=${this.filter.denNgay}`).then(res => {
          if (res.data?.succeeded) {
            const result = res.data.data
            this.thuPhiLePhi = [result.hoSoCanThuPhi, result.hoSoChoThuPhi, result.hoSoDaThuPhi]
            this.thuPhiLePhiPercentage = [result.tyLeCanThuPhi, result.tyLeChoThuPhi, result.tyLeDaThuPhi]
          }
        })
      }
    },
    getDataXuLyHoSo() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = formatDateRequestTime(date.dateBefore)
        this.filter.denNgay = formatDateRequestTime(date.dateAfter)
        this.$axios.get(`${END_POINTS.TRANG_CHU.XU_LY_HO_SO}?tuNgay=${this.filter.tuNgay}&&denNgay=${this.filter.denNgay}`).then(res => {
          if (res.data?.succeeded) {
            const result = res.data.data
            this.xuLyHoSo = [result.hoSoGiaiQuyet, result.hoSoTuChoiGiaiQuyet]
            this.xuLyHoSoPercentage = [result.tyLeHoSoGiaiQuyet, result.tyLeHoSoTuChoiGiaiQuyet]
          }
        })
      }
    },
    activeTime(id) {
      this.activeTimeSelect = id
      this.timeOption.forEach((time, index) => {
        this.timeOption[index].active = false
        if (time.id === id) {
          this.timeOption[index].active = true
        }
      })
    },
    layThoiGianBaoCao() {
      let dateBefore = new Date()
      let dateAfter = new Date()
      // eslint-disable-next-line default-case
      switch (this.activeTimeSelect) {
        case 1:
          // dateBefore = moment(new Date(this.time.year, this.time.month - 1, 1)).startOf('month').format('YYYY/MM/DD')
          // dateAfter = moment(new Date(this.time.year, this.time.month - 1, 1)).endOf('month').format('YYYY/MM/DD')
          if (this.time.month === 2) {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(1)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(2)
            dateAfter.setDate(14)
          } else {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(this.time.month - 1)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(this.time.month)
            dateAfter.setDate(14)
          }
          if (this.time.month === 3) {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(2)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(3)
            dateAfter.setDate(14)
          } else {
            dateBefore.setFullYear(this.time.year)
            dateBefore.setMonth(this.time.month - 1)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(this.time.month)
            dateAfter.setDate(14)
          }
          // if (!this.time.month) {
          //   dateBefore = ''
          //   dateAfter = ''
          // }
          if (this.time.month === 1) {
            dateBefore.setFullYear(this.time.year - 1)
            dateBefore.setMonth(12)
            dateBefore.setDate(15)
            dateAfter.setFullYear(this.time.year)
            dateAfter.setMonth(1)
            dateAfter.setDate(14)
          }
          if (this.time.month === undefined) {
            dateBefore = ''
            dateAfter = ''
          }
          return {
            dateBefore,
            dateAfter,
          }
        case 2:
          // dateBefore.setFullYear(this.time.year)
          // dateBefore.setMonth(this.time.quarter - 1)
          // dateBefore = moment().quarter(this.time.quarter).startOf('quarter').format('YYYY/MM/DD')
          // // dateAfter.setFullYear(this.time.year)
          // // dateAfter.setMonth(this.time.quarter + 2)
          // dateAfter = moment().quarter(this.time.quarter).endOf('quarter').format('YYYY/MM/DD')
          dateBefore.setFullYear(this.time.year)
          dateBefore.setMonth(this.time.quarter - 1)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(this.time.quarter + 2)
          dateAfter.setDate(14)

          if (this.time.quarter === undefined) {
            dateBefore = ''
            dateAfter = ''
          }
          return {
            dateBefore,
            dateAfter,
          }
        case 3:
          // dateBefore = new Date(this.time.year, 0, 1)
          // dateAfter = new Date(this.time.year, 11, 31)
          dateBefore.setFullYear(this.time.year - 1)
          dateBefore.setMonth(11)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(11)
          dateAfter.setDate(14)
          if (this.time.year === undefined) {
            dateBefore = ''
            dateAfter = ''
          }
          return {
            dateBefore,
            dateAfter,
          }
        case 4:
          if (this.time.date) {
            [dateBefore, dateAfter] = this.time.date
          }
          return {
            dateBefore,
            dateAfter,
          }
        default: return {
          dateBefore,
          dateAfter,
        }
      }
    },
    // disabledYear(date) {
    //   const dateBefore = new Date()
    //   const dateAfter = new Date()
    //   if (this.time.year) {
    //     dateBefore.setFullYear(this.time.year - 1, 11, 14)
    //     dateAfter.setFullYear(this.time.year, 11, 14)
    //   }
    //   return date < dateBefore || date > dateAfter
    // },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.btn-outline-white{
    border: 1px solid #ddd !important;
  }
</style>
