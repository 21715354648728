<template>
  <section>
    <b-card class="p-0 m-0">
      <div class="mb-1 text-uppercase">
        <b>{{ title }}</b>
      </div>
      <div class="custom-grid-xl-container ">
        <div class="grap-6 d-flex d-sm-block justify-content-center">
          <apexchart
            width="200"
            height="200"
            type="donut"
            :options="options"
            :series="series"
          />
        </div>
        <div class="grap-6">
          <div
            v-for="(item, index) in label"
            :key="`${item}_${index}`"
          >
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div :class="index === 0 ? 'dot-red' : index === 1 ? 'dot-primary' : 'dot-yellow'" />
                <div class="ml-1">{{ item }}</div>
              </div>
              <div :class="index === 0 ? 'text-red-cor' : index === 1 ? 'text-blue-cor' : 'text-yellow-cor'">
                {{ formatNumber(series[index]) }} ({{ percentage[index] }}%)
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'TrangChu',
  components: {
    BCard,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    label: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    percentage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        colors: ['#ED5050', '#5899DA', '#FF9F43'],
      },
    }
  },
  methods: {
    formatNumber(number) {
      return number ? number.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    },
    percentageNumber(num, total) {
      if (total !== 0) {
        return ((num / total) * 100).toFixed(2)
      }
      return 0
    },
  },
}
</script>

<style scoped></style>
