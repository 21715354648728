export const ROUTE_NAME = {
  TRANG_CHU: {
    NAME: '/trang-chu/',
  },
}

export const MENU_ROUTE_NAME = {
  TRANG_CHU: {
    NAME: 'Trang chủ',
  },
}

export const MONTHS = [
  {
    name: 'Tháng 1',
    id: 0,
  },
  {
    name: 'Tháng 2',
    id: 1,
  },
  {
    name: 'Tháng 3',
    id: 2,
  },
  {
    name: 'Tháng 4',
    id: 3,
  },
  {
    name: 'Tháng 5',
    id: 4,
  },
  {
    name: 'Tháng 6',
    id: 5,
  },
  {
    name: 'Tháng 7',
    id: 6,
  },
  {
    name: 'Tháng 8',
    id: 7,
  },
  {
    name: 'Tháng 9',
    id: 8,
  },
  {
    name: 'Tháng 10',
    id: 9,
  },
  {
    name: 'Tháng 11',
    id: 10,
  },
  {
    name: 'Tháng 12',
    id: 11,
  },
]

export const YEARS = () => {
  const currentYear = new Date()
  const result = []
  for (let i = 0; i < 5; i += 1) {
    const year = currentYear.getFullYear() - i
    result.push({
      name: `Năm ${year}`,
      id: year,
    })
  }
  return result
}

export const QUARTERS = [
  {
    name: 'Quý I',
    id: 0,
  },
  {
    name: 'Quý II',
    id: 3,
  },
  {
    name: 'Quý III',
    id: 6,
  },
  {
    name: 'Quý IV',
    id: 9,
  },
]
