<template>
  <section>
    <div
      class="border-khung-thong-ke"
      :class="`box-shadow-${khung.color}-cor cursor-pointer`"
      @click="gotoPage(khung.path)"
    >
      <div class="d-flex justify-content-between p-12-13">
        <img :src="require(`@/assets/images/dashboard/${khung.svg}`)">
        <div>
          <span class="m-0 p-0">{{ khung.name }}</span>
          <div class="d-flex justify-content-end">
            <h1
              :class="`text-${khung.color}-cor`"
              style="font-weight: 700;"
            >
              {{ khung.value }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TrangChu',
  props: {
    khung: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    gotoPage(path) {
      if (path) {
        this.$router.push({ path, query: this.filter })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.border-khung-thong-ke {
  background: #FFFFFF;
  border-radius: 5px;
  .p-12-13 {
    padding: 17px 15px 0px 15px !important;
  }
  span:last-child {
    font-size: 20px;
  }
}
</style>
